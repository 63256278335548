// hero
.hero {
	padding: 5rem 0;
	height: 80vh;
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
	position: relative;

	.container {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
	}

	&--home {
		@media (max-width: 767px) {
			background-position: -113px center;
		}
		height: 100vh !important;
		.container {
			top: 50%;
			transform: translateY(-50%);
		}

		h1 {
			color: white;
			text-transform: uppercase;
			max-width: 500px;
			position: relative;

			@media(max-width: 768px) {
				margin-bottom: 70px;
			}

			span {
				color: $primary;
			}

			&:after {
				content: '';
				position: absolute;
				background: url(../images/skylab-logo-white.svg);
				background-repeat: no-repeat;
				background-size: cover;
				width: 100px;
				height: 30px;

				@media (max-width: 767px) {
					bottom: -40px;
					left: 0;
				}

				@media(min-width: 768px) {
					right: -30px;
					width: 163px;
					height: 50px;
					bottom: 0;
				}
			}
		}

		.small-span {
			width: 250px;
			font-family: $secondary-font;
			text-transform: uppercase;
			font-size: 24px;

			@media (min-width: 768px) {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 200px;
			}
		}
	}

	&--section {
		margin-bottom: 150px;
		
		.container {
			bottom: 0;
			margin-bottom: -160px;
		}

		h1 {
			text-transform: uppercase;
			position: relative;
			display: inline flow-root list-item;
			list-style: none !important;

			span {
				color: $secondary;
			}

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				background: url(../images/skylab-logo-black.svg);
				width: 163px;
				height: 50px;
				background-repeat: no-repeat;
				background-size: cover;

				@media(max-width: 767px) {
					width: 100px;
					height: 30px;
					bottom: -40px;
					left: 0;
				}
			}
		}

		.section-label {
			color: $secondary;
			text-transform: uppercase;
		}
	}
}