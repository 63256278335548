// Footer
footer {
	margin-top: 80px;
	background-color: $primary;
	padding: 1rem;
	font-size: 12px;
	color: white;

	@media (max-width: 992px) {
		text-align: center !important;
	}

	a {
		color: white !important;

		span {
			margin-right: 5px;
		}
	}

	ul {
		margin-bottom: 0;
		margin-top: 0;
	}
	.col-lg-1,
	.col-lg-3,
	.col-lg-5 {
		@media (max-height: 767px) {
			margin: 1rem 0;
		}
	}
}

#contact-logos {
	.row {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.footer-logo {
	img {
		max-width: 420px;
		margin: 0 auto;
	}
}