// Global
body {
	background: white !important;
	padding-top: 50px;
	max-width: 100%;
	overflow-x: hidden;
}

.container {
	@media (min-width: 1200px) {
		max-width: 1300px;
	}
}

// Spacing
.padding-top {
	padding-top: 98px;
}

.padding-bottom {
	padding-bottom: 98px;
}

.additonal-padding-top {
	padding-top: 148px;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.margin-top {
	margin-top: 98px;
}

.margin-top-desktop {
	@media(min-width: 992px) {
		margin-top: 98px;
	}
}