// Building section
#building {
	background-image: url(../images/stacked-plates.png);
	background-position: bottom -792px right -360px;
	background-repeat: no-repeat;
	background-size: 60%;

	.hero--section {
		@media (max-width: 767px) {
			background-position: -250px center;
		}
	}
}

#building-sub-section {
	#icons-container {
		background-image: url(../images/down-plates.png);
		background-position: top right -80px;
		background-repeat: no-repeat;
		background-size: 35%;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $danger;
	}

	.hero--section {
		h1 {
			span {
				color: $danger;
			}
		}
	}
}