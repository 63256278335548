// Icon
.icon-holder {
	h4 {
		font-family: $primary-font;
		font-size: 20px;
		text-transform: uppercase;
		letter-spacing: 1.11px;
	}
}

.icon {
	height: 150px;
	width: 150px;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-position: center;
}

.icon-heading {
	height: 100px;
}