// Vendor

// Colours
$primary: #EA893F;
$secondary: #BE9C32;
$success: #BD9BC8;
$info: #759C5A;
$warning: #B869B1;
$danger: #00A9DE;

// Greyscale
$grey-001: #000;
$grey--002: #6B6A6A;
$grey--003: #AFAFAF;
$grey--004: #ECEEF1;
$grey--005: #FAFAFA;
$grey--006: #FFF;
$grey--007: #969696;
$grey--008: #212020;
$grey--009: #c9c9c9;

// Fonts
$primary-font: proxima-nova,sans-serif; 
$secondary-font: 'Anton', sans-serif; 