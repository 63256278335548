// Blocks
.gold-block {
	background-color: $secondary;
	color: white;
	padding: 3rem;
	margin-bottom: 90px;
	

	@media (min-width: 992px) {
		position: absolute;
	    z-index: 2;
	    bottom: -140px;
	    margin-bottom: 0;
	    max-width: 356px;
	}

	p, h4 {
		color: white;
	}
}