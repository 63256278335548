// Buttons
.btn {
	border-radius: 0;
	border: none;
	font-family: $primary-font;
	font-weight: 500;
	font-size: 18px;
	color: white !important;
	padding: 1rem 4rem;
	text-transform: uppercase;

	&-primary {
		background-color: $secondary;
		border: 1px solid $secondary !important;

		&:hover,
		&:active,
		&:focus {
			background: transparent !important;
			color: $secondary !important;
		}
	}

	&-warning {
		background-color: $warning;
		border: 1px solid $warning !important;
		margin-top: 28px;
		padding: .6rem 2rem;

		&:hover,
		&:active,
		&:focus {
			background: transparent !important;
			color: $warning !important;
		}
	}

	&-virtual-tours {
		width: 100%;
		font-size: 18px;
		border: 1px solid $secondary !important;
		background-image: url('../images/360.svg');
		background-size: 65px;
		background-repeat: no-repeat;
		background-position: 25% center;
		padding-left: 115px;
		max-width: 400px;
		margin-bottom: 1rem;

		@media (min-width: 767px) {
			margin-bottom: 0;
		}

		&:hover,
		&:active,
		&:focus {
			background: transparent !important;
			color: $secondary !important;
			background-image: url('../images/360-gold.svg') !important;
			background-size: 65px !important;
			background-repeat: no-repeat !important;
			background-position: 25% center !important;
			padding-left: 115px !important;
			max-width: 400px !important;
		}

		span {
			margin-right: 10px;
		}
	}
}