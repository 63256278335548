// Availability
.availability-block {
	margin-top: 70px;
	position: relative;
	border-top: 3px solid $danger;
	padding-top: 20px;

	p {
		font-size: 20px;
		color: $danger;
		margin-bottom: 2rem;
	}

	&:first-child {
		margin-top: 140px !important;
	}
}

.info {
	h3 {
		color: $grey--008 !important;
	}
}

#availability-figures {
	@media (max-width: 767px) {
		margin-bottom: 98px;
	}

	@media (min-width: 992px) {
		margin-top: -40px;
	}

	h3 {
		text-transform: uppercase;
		@media(min-width: 992px) {
			max-width: 300px;
		}
	}
}