// Services
#services {
	background-image: url("../images/Connected Cubes.svg");
	background-position: bottom left -150px;
	background-repeat: no-repeat;
	padding-bottom: 100px;
	background-size: 75%;

	@media (min-width: 768px) {
		padding-bottom: 240px;
	}
	
	.hero {
		.section-label {
			color: $warning;
		}

		span {
			color: $warning;
		}

		&--section {
			@media (max-width: 767px) {
				background-position: top center;
			}
		}
	}

	.carousel-categories,
	.carousel-categories-nav-slider {
		h4 {
			font-size: 67px;
			text-transform: uppercase;
			span {
				color: $warning;
			}
		}

		h5 {
			color: $warning;
			font-size: 20px;
			text-transform: uppercase;
		}
	}
}