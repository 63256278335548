// Nav
.bg-light {
	background-color: #fff !important;
}
.nav-link {
	font-family: proxima-nova,sans-serif;
	font-weight: 500;
	font-size: 20px;
	letter-spacing: 1.11px;
	line-height: 24px;
	color: #969696;
	text-transform: uppercase;
	padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.navbar-toggler {
	border: none !important;
}

.navbar-nav {
	@media (max-width: 991px) {
		padding: 3rem 0;
	}
}

.dropdown-toggle,
.dropdown-item {
	color: $danger !important;
}

.dropdown-toggle:after {
	background-image: url(../images/lang-chev.svg);
	height: 9px;
	width: 18px;
	border: none;
	background-repeat: no-repeat;
	position: absolute;
	top: 17px;
	right: -6px;
}

// Tabs
.nav-tabs {
	border-bottom: 0;

	@media (min-width: 992px) {
		position: absolute;
		top: 60px;
		z-index: 9999;
	}

	.nav-item {
		border: 1px solid $grey--009;
		border-radius: 0;
		padding: 60px;
		width: 28%;
		margin: 5px;
		text-align: center;
		position: relative;

		@media (min-width: 992px) {
			height: 220px;
			width: 220px;
			padding: 15px;
		}

		#Fill-1 {
			fill: $grey--009;
		}

		#Fill-11 {
			fill: $grey--009;
		}

		h5 {
			display: none;

			@media (min-width: 992px) {
				color: $grey--009 !important;
				display: block;
			}
		}

		&.active {
			border: 1px solid $warning;

			h5 {
				color: $warning !important;
			}

			#Fill-1 {
				fill: $warning !important;
			}

			#Fill-3 {
				fill: $warning !important;
			}

			#Fill-5 {
				fill: $warning !important;
			}

			#Fill-7 {
				fill: $warning !important;
			}

			#Fill-9 {
				fill: $warning !important;
			}

			#Fill-11 {
				fill: $warning !important;
			}
		}

		&:hover {
			border: 1px solid $warning;
			background-color: $warning;

			#Fill-1 {
				fill: #fff !important;
			}

			#Fill-3 {
				fill: #fff !important;
			}

			#Fill-5 {
				fill: #fff !important;
			}

			#Fill-7 {
				fill: #fff !important;
			}

			#Fill-9 {
				fill: #fff !important;
			}

			#Fill-11 {
				fill: #fff !important;
			}

			h5 {
				color: white !important;
			}
		}
	}
}

.tab-info {
	@media (min-width: 992px) {
		margin-top: 280.5px;
	}
}

.icon-and-heading {
	position: absolute;
	top: 45%;
	transform: translateY(-50%);
	width: 87%;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;

	svg {
		@media (max-width: 991px) {
			width: 55%;
		}
	}
}

.tab-pane {
	display: block !important;
  	height: 0;
  	overflow: hidden;

  	&.active {
  		height: auto;
  	}
}

.tab-content {
	padding-top: 50px;

	@media (min-width: 1200px) {
		width: 60%;
		margin: 15px;
	}
}