// Contact card
.contact-card {
	text-transform: uppercase;

	@media (max-width: 992px) {
		margin-top: 50px;
	}

	p {
		margin-bottom: 0;
	}

	strong {
		// margin-bottom: 1rem;
		display: block;
	}

	a {
		color: #000 !important;

		&:hover {
			color: #000 !important;
		}
	}
}