// Carousel
.slick-slide {
	height: auto !important; 
}

.slick-next,
.slick-prev {
	top: auto !important;
	bottom: 51px;
	z-index: 2;
	width: 58px;
	height: 80px;
	margin-bottom: -90px;
	&:before {
		display: none;
	}
}

.slick-prev {
	left: 0;
}
.slick-next {
	left: 59px;
}

.carousel {
	@media (max-width: 991px) {
		margin-bottom: 5px !important;
		margin-top: 50px;
	}
	
	@media (min-width: 992px) {
		margin-top: 125px;
	}
}

// Tenants 
.carousel-tenants {
	.slick-slide {
		margin: 15px;
	}

	.slick-next, .slick-prev {
		bottom: -45px;
		@media (min-width: 768px) {
			bottom: 65px;
		}
	}

	.slick-prev {
		left: 15px;
		@media(min-width: 768px) {
			left: -160px;
		}    
	}

	.slick-next {
		left: 74px;
		@media(min-width: 768px) {
			left: -100px;
		}
	}
}
.tenant-slide {
	border: 1px solid $danger;
	padding: 30px;
	height: 100%;
	position: relative;
	min-height: 650px;

	p {
		color: $danger;
		font-size: 20px;
		line-height: 37px;
		text-align: center;
		padding-top: 50px;
		position: relative;
		
		&:before {
			content: '';
			border-top: 1px solid $danger;
			width: 85%;
			top: 0;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
			position: absolute;
		}
	}

	img {
		margin: 0 auto 15px;
	}
}

.tenant-slide-content {
	@media (min-width: 992px) {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 85%;
	}
}

// Dots
.slick-dots {
	width: auto !important;
	bottom: -50px !important;
}
.slick-dots li {
	width: 20px;
}

.slick-dots li button::before {
	width: 30px;
	height: 2px;
	background-color: $secondary;
	color: transparent;
}

.slick-dots li.slick-active button::before {
	color: transparent;
	height: 4px;
	margin-top: -1px;
}

.carousel-sub-slider {
	@media (max-width: 992px) {
		margin-bottom: 98px;
		margin-top: 40px;
	}
	
	.slick-dots li button::before {
		background-color: $warning !important;
	}
}

.carousel-categories-nav-slider {
	.slick-next,
	.slick-prev {
		margin-bottom: -180px;
	}
}

.carousel-categories {
	margin-top: 125px;
	@media (min-width: 992px) {
		margin-top: 55px;
	}
}