// Localisation
#localisation {
	.hero {
		height: auto;
		position: relative;
		@media (max-width: 767px) {
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.35);
			}
		}

		&--section {
			padding-bottom: 330px;
			@media (max-width: 767px) {
				background-position: center;
			}
			.container {
				position: relative;
				margin-bottom: 0;
			}
		}

		h1 {
			color: #fff;
			span {
				color: $info;
			}

			&:after {
				background-image: url(../images/skylab-logo-white.svg);
			}
		}

		.section-label {
			color: $info;
		}

		p {
			color: #fff;
		}
	}
}

#localisation-content {
    padding-top: 280px;
    position: relative;

	&:before {
		content: '';
		background-image: url(../images/pin-graphic.svg);
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		height: 380px;
		width: 230px;
		top: 0;
		left: -120px;
	}

	@media (max-width: 767px) {
		p {
			font-size: 16px;
		}
	}
	
}


#map {
	margin-top: -500px;

	.col {
		@media (max-width: 992px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}
}