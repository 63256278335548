// Typography
p {
	font-family: $primary-font;
	font-weight: 400;
	font-style: normal;
	font-size: 14px;
	color: #000;
	line-height: 24px;

	&.large {
		font-size: 18px;
		line-height: 28px;
		text-transform: uppercase;
		color: #212020;
	}

	&.small {
		font-size: 12px;
		color: $grey--002;
		line-height: 18px;
	}
}

h1,
h2,
h3,
h4,
h5 {
	font-family: $secondary-font;
}

h1 {
	font-size: 90px;
	line-height: 90px;
	letter-spacing: 10.5px;
	color: #000;
	
	@media (min-width: 767px) {
		font-size: 140px;
		line-height: 140px;
	}
	
	&.title {
		font-size: 52px !important;
	}
}

h2 {
	font-size: 70px;
	letter-spacing: 7px;
	line-height: 75px;
	color: #000;

	&.subtitle {
		font-size: 52px;
		color: $grey--002;
	}

	&.secondary {
		color: $secondary;
	}
}

h3 {
	font-size: 38px;
	color: #000;
	line-height: 56px;

	&.grey--007 {
		color: $grey--007;
	}
}

h4 {
	color: #212020;
	font-size: 24px;
	text-transform: uppercase;
}

h5 {
	color: #000;
	font-size: 20px;
	letter-spacing: 1.88px;
	margin-bottom: 1rem;
}

.lead {
	font-size: 18px;
	line-height: 28px;
	text-transform: uppercase;
	margin-bottom: 50px;

	p {
		color: #212020;
		font-size: 18px;
		line-height: 28px;
	}
}

.intro-text {
	p {
		font-family: $secondary-font;
		text-transform: uppercase;
		font-size: 40px !important;
		line-height: 56px !important;
		letter-spacing: 2px !important;
		color: $grey--002 !important;
	}
}

.right-text {
	text-align: center;
	@media (min-width: 992px) {
		text-align: right;
	}
}

.left-text {
	text-align: center;
	@media (min-width: 992px) {
		text-align: left;
	}
}

.list-inline {
	margin-left: 0;
}