// Required
@import 'global';
@import 'vendor';
@import 'mixins';

// Base
@import 'base/buttons';
@import 'base/header';
@import 'base/footer';
@import 'base/nav';
@import 'base/typography';

// Layouts
@import 'layout/building';
@import 'layout/contact';
@import 'layout/localisation';
@import 'layout/services';
@import 'layout/tenants';

// Partials
@import 'partials/availability';
@import 'partials/carousel';
@import 'partials/contact-card';
@import 'partials/blocks';
@import 'partials/hero';
@import 'partials/icon';
@import 'partials/image-left-right';