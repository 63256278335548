// Image left right 
.image-left-right {
	li {
		list-style: none;
		margin: 120px 0;
		position: relative;
		max-width: 374px;

		p {
			font-family: $secondary-font;
			font-size: 24px;
			line-height: 35px;
			color: $primary;
			text-transform: uppercase;
		}

		small {
			font-family: $primary-font;
			font-size: 14px;
			color: #000;
			text-transform: none;
			line-height: 24px;
			margin-top: 1rem;
			display: block;
		}

		&:before {
			content: '';
			position: absolute;
			background: url(../images/orange-cube.svg);
			width: 108px;
			height: 123px;
			top: -65px;
			left: -65px;
		}
	}

	&--img-right-desktop {
		ul {
			@media (min-width: 1200px) {
				margin-top: -200px;
			}
		}
	}
}