// Localisation
#contact {
	.hero {
		background-color: $primary;
		height: 397px;

		@media (min-width: 768px) {
			&:after {
				content: '';
				position: absolute;
				top: -145px;
				right: 100px;
				background-image: url(../images/Stackedcubes.svg);
				background-repeat: no-repeat;
				height: 618px;
				width: 340px;
				background-size: cover;
			}
		}

		&--section {
			.container {
				position: relative;
				margin-bottom: 0;
			}

			@media (max-width: 767px) {
				margin-bottom: 220px;
			}
		}

		h1 {
			color: #fff;
			span {
				color: white;
				@media (min-width: 768px) {
					color: $primary;
				}
			}

			&:after {
				background-image: url(../images/skylab-logo-black.svg);
			}
		}

		.section-label {
			color: #fff;
		}

		p {
			color: #000;
			font-size: 20px !important;
			line-height: 32px !important;

			@media(min-width: 767px) {
				color: white;
			}
		}

		.lead {
			margin-top: 60px;
		}
	}
}

#contact-form {
	background-color: rgba(#EB8A40, 0.05);
	min-height: 780px;
	.section-label {
		max-width: 350px;
		margin-left: 200px;
		margin-top: 180px;
		font-family: $secondary-font;
		font-size: 67px;
		color: #000000;
		letter-spacing: 0;
		line-height: 74px;
		text-transform: uppercase;
		span {
			display: block;
			&:last-child {
				color: $primary;
			}
		}
	}
	form {
		margin-top: 80px;
	}
	label {
		text-transform: uppercase;
		font-family: $secondary-font;
		font-size: 14px;
		color: #000000;
		letter-spacing: 0.5px;
		line-height: 18px;
		&.form-check-label {
			font-family: $primary-font;
			text-transform: initial;
			font-size: 11px;
			line-height: 13px;
			transform: translateY(-0.3em);
			padding-left: 0.3em;
			a {
				color: currentColor;
				text-decoration: underline;
			}
		}
	}
	.form-control {
		border-radius: 0;
		background-color: transparent;
		border-color: #969696;
		padding: 20px 10px;
		margin-bottom: 2em;
		&:focus {
			border-color: $primary;
			box-shadow: none;
		}
		&[name=message] {
			padding:10px;
			min-height: 120px;
			resize: none;
		}
	}
	// input[type=checkbox] {
	// 	opacity: 0;
	//     width: 1em;
	//     height: 1em;
	// }
	.btn-primary {
		background-color: $primary;
		border-color: $primary !important;
		&:hover {
			border-color: $primary !important;
			color: $primary !important;
		}
	}
}
.contact-form--cubes-decor {
	position: relative;
	&:after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		background-image: url('../images/cubes.svg');
		background-repeat: no-repeat;
		background-position: -100px center;
		z-index: -1;
	}
}

/* checkbox */
.jcf-checkbox {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	overflow: hidden;
	cursor: default;
	background: transparent;
	border: 1px solid #969696;
	margin: 0 3px 0 0;
	height: 16px;
	width: 16px;

	position: absolute;
	left: 0;

}
.jcf-checkbox span{
	position:absolute;
	display:none;
	height:4px;
	width:8px;
	top:50%;
	left:50%;
	margin:-7px 0 0 -6px;
	border:3px solid $primary;
	border-width:0 0 3px 3px;
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')";
}
:root .jcf-checkbox span {margin:-4px 0 0 -5px;}
.jcf-checkbox input[type="checkbox"] {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 0;
	margin: 0;
	left: 0;
	top: 0;
}
.jcf-checkbox.jcf-checked {
	border-color: $primary;
}
.jcf-checkbox.jcf-checked span{display:block;}